// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-search-template-jsx": () => import("/Users/nick/Desktop/Dev/judithynikolas/src/templates/SearchTemplate.jsx" /* webpackChunkName: "component---src-templates-search-template-jsx" */),
  "component---src-templates-lucky-template-jsx": () => import("/Users/nick/Desktop/Dev/judithynikolas/src/templates/LuckyTemplate.jsx" /* webpackChunkName: "component---src-templates-lucky-template-jsx" */),
  "component---src-pages-app-jsx": () => import("/Users/nick/Desktop/Dev/judithynikolas/src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-index-js": () => import("/Users/nick/Desktop/Dev/judithynikolas/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lucky-jsx": () => import("/Users/nick/Desktop/Dev/judithynikolas/src/pages/lucky.jsx" /* webpackChunkName: "component---src-pages-lucky-jsx" */),
  "component---src-pages-search-jsx": () => import("/Users/nick/Desktop/Dev/judithynikolas/src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/nick/Desktop/Dev/judithynikolas/.cache/data.json")

